import React, {useState} from 'react'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Image from "react-bootstrap/Image";
import faq from '../../assets/images/CONTACTUS 2.png'
import mail from '../../assets/images/mail icon 3d 1.png'
import call from '../../assets/images/contactcall.png'
import contactmsg from '../../assets/images/contactmsg.png'
import contextloc from '../../assets/images/contactloc.png' 
import Form from 'react-bootstrap/Form'
import ScrollToTop from '../ScrollToTop'
import Contactform from '../Contactform';
import Contactformnext from '../Contactformnext';
import {Helmet} from "react-helmet";


import '../../styles/home.css'
import '../../styles/Faqpage.css'
import "../../styles/Navbar.css"
import "../../styles/Error_Credit_Removal.css"
import "../../styles/ContactUs.css"
import '../../styles/Contactformnext.css'
import { Button } from 'bootstrap';

function ContactUs() {


  const keepintouch = () => {
    return(
      <>
        <div>

         <Container className="header-container" fluid>
          <Row className="row-card ">
           <Col className="col-card leftcontact">
             <Row className="divhead"><h2>KEEP IN TOUCH WITH US</h2></Row>
             <Row>
               <div className="divbox">
                <a href="tel:(949) 444-5675">
                   <Image className="divboxmage" src={call}/>
                   <span>(949) 444-5675</span>
                  </a>
               </div>
             </Row>
             <Row>
             <div className="divboxmsg">
                   <a href="mailto: Info@AprilsCreditRepair.com" target="_blank" >
                   <Image className="divboxmage" src={contactmsg}/>
                   <span>Info@AprilsCreditRepair.com</span>
                   </a>
               </div>
             </Row>
             <Row>
            
               <div className="divboxloc">
                   <a href="http://maps.google.com/?q=27201 Puerta Real Suite 300 Mission Viejo, CA 92691" target="_blank" >
                   <Image className="divboxmage" src={contextloc}/>
                   <span>27201 Puerta Real Suite 300 Mission Viejo, CA 92691</span>
                   </a>
              </div>
             </Row>
           
           </Col>
           
           <Col className="col-card"><Image className='divboxmail' src={mail}/></Col>
          </Row>
          <Row className="row-bottom"></Row>
         </Container>
        </div>
      </>
    )
  }

  return (
   <>
   <ScrollToTop/>
              <Helmet>
                <meta charSet="utf-8" name="description" content="Get a credit repair service with valuable tips and tricks for a healthy credit report. Contact April's Credit Repair to raise your credit scores."/>
                <title>Contact Us To Enhance Your Credit Score | April's Credit Score</title>
                <link rel="canonical" href="http://mysite.com/example" />
              </Helmet>
        <Container  className="header-image " fluid>
        <Row className="contactmain">
            <Col xs={12} md={6} lg={6} className="colimagecontact contactcard align-items-center">
                <Row >
                <h2 className="text"><span>Contact Us</span></h2>
                </Row>
                <Row className="coltext">
               <p>We love hearing from you. 
               Have questions or interested in a free credit counseling consultation? We can help.</p>
                </Row>
            </Col>
            <Col className="siderimage" xs={12} md={6} lg={6}>
               <Image className="contactimage"   width={"60%"} height={"auto"} src={faq}/>
            </Col>
        </Row>
       </Container>
       {keepintouch()}

       <Contactform/>
      
      
     

   </>
  )
}

export default ContactUs;