import React , {useState} from 'react'
import '../../styles/home.css'
import '../../styles/Faqpage.css'
import "../../styles/Navbar.css"
import "../../styles/Error_Credit_Removal.css"
import ScrollToTop from '../ScrollToTop';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Image from "react-bootstrap/Image";
import faq from '../../assets/images/faq.png'
import Faq from "../../components/Faq"
import {Helmet} from "react-helmet";


function Faqpage() {
  const [faqs, setfaqs] = useState([
        {
          question: 'How do I get started?',
          answer: 'Please fill out the quick questionnaire on the contact page, or you may simply email or call us. ',
          open: false
        },
        {
          question: 'What is the cost?', 
          answer: `Cost /fees:\nPrices:\n$399.00 Per Administrative Services\n$149.00 Per month - 6 Month Agreement - Client can cancel any time before the 6-month completion\n$695.00 Per 1st litigation settlement\n$595 per 2nd settlement\n$495 per 3rd settlement (lawsuits-attorneys)\n$55.00 Per Creditor Settlement- First settlement is free`,
          open: false
        },
        {
          question: 'What makes Our process different than most credit companies?',
          answer: 'We challenge every incorrect or negative information right from the start to speed up the process the best we can. We will negotiate with the creditors and or attorneys, and complete formal complaints if necessary. ',
          open: false
        }
      ]);

 const [nextfaqs, setnextfaqs] = useState([
        {
          question: 'What is included?',
          answer: 'We help our clients build a good credit history for 6 months. We educate our client on what they need to do and which accounts to open. Often times, helping them acquire up to 10k+ in individual unsecured credit lines (guaranteed approval) even with low scores. These credit builder accounts are designed for credit building and making small monthly payments to generate a good score. Along with that valuable information, we teach them how to maintain their good credit          ',
          open: false
        },
        {
          question: 'When will I see results?', 
          answer: 'We go through phases of 30 days per the credit bureaus. We will monitor your credit reports for any changes.   You may see results faster from the creditors.  ',
          open: false
        },
        {
          question: 'How long does our contract last?',
          answer: 'Our contracts are highly regulated by the state and federal laws which allow us to complete the contract for up to 6 months if needed',
          open: false
        },
        {
            question: 'Do you share our personal information with anyone?',
            answer: "We proudly use Natural Networks. That is a question we hear a lot because of all the data Breaches with the credit reporting companies. Our Company's servers are highly secure with comprehensive security solutions for high availability mission control websites. All of your personal information is securely held and not shared with anyone.",
            open: false
        },
        
      ]);

    const toggleFAQ = index => {
        setfaqs(faqs.map((faq, i) => {
          if (i === index) {
            faq.open = !faq.open
          } else {
            faq.open = false;
          }
    
          return faq;
        }))
      }

    const toggleNextFAQ = index => {
        setnextfaqs(nextfaqs.map((faq, i) => {
          if (i === index) {
            faq.open = !faq.open
          } else {
            faq.open = false;
          }
    
          return faq;
        }))
      }

  return (
   <>
   <ScrollToTop/>
             <Helmet>
                <meta charSet="utf-8" name="description" content="You have got questions. We've got answers. What is credit repair? • Can bad credit be corrected or deleted? Can my credit be repaired? How long does credit repair take place? Ask all questions, and we will clear your doubts."/>
                <title>Frequently Asked Questions(FAQ) About Our Credit Repair Services</title>
                <link rel="canonical" href="http://mysite.com/example" />
              </Helmet>
    <Container  className="header-image " fluid>
        <Row className="serviceimages">
            <Col xs={12} md={6} lg={6} className=" coliimagefaq align-middle">
                <Row >
                <h2 className="text"><span>Frequently Asked Questions</span></h2>
                </Row>
                <Row className="coltext">
               <p>Get Answers</p>
                </Row>
            </Col>
            <Col xs={12} md={6} lg={6}>
               <Image className="image-faqpage"  width={"70%"} height={"auto"} src={faq}/>
            </Col>
        </Row>
    </Container>

    <div className="restore-main-container">
          <Container className='faq-containerfaq'>
  
                <Row xs={12} md={12} lg={12}>
               <Col>
               <div className='faqs-head-faq'>
                
                <h1>FAQ</h1>
                <p>Here are some popular questions and answers about our services.</p>
                <h6>About April’s Credit Repair</h6>
                </div>

                
               </Col>
               </Row>
               <Row xs={12} md={12} lg={12}>
                <Col>
                   <div className="faqspage">
                        {faqs.map((faq, i) => (
                            <Faq faq={faq} index={i} toggleFAQ={toggleFAQ} />
                         ))}
                    </div>
                </Col>
                </Row>
   
   <Row className="mid-text">
      <h6>What makes April’s credit repair unique?</h6>
   </Row>
   

   <Row xs={12} md={12} lg={12}>
   <Col>
    <div className="faqspage">
     {nextfaqs.map((faq, i) => (
       <Faq faq={faq} index={i} toggleFAQ={toggleNextFAQ} />
     ))}
   </div>
   </Col>
  </Row>
  <div id="dotone"></div>
      <div id="dottwo"></div>
      <div id="dotthree"></div>
      <div id="dotfour"></div>
      <div id="dotfive"></div>
          </Container>
    </div>
   </>
  )
}

export default Faqpage