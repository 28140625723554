import React , {useState, useEffect} from 'react'
import '../../styles/home.css'
import "../../styles/Navbar.css"
import "../../styles/Error_Credit_Removal.css"
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Image from "react-bootstrap/Image";
import hand from '../../assets/images/hand.png'
import serviceaction from '../../assets/images/service-action.png'
import frame from '../../assets/images/Frame 64.png'
import Navbar from '../Navbar';
import ScrollToTop from '../ScrollToTop';
import { NavLink, useLocation } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';
import {Helmet} from "react-helmet";

function ScrollToTopOnMount() {
    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);
  
    return null;
  }

function Error_Credit_Removal() {
    
 

  const location = useLocation()
  

  useEffect(() => {
    console.log(location.pathname , 'services-------')

    document.querySelector(".render-servise-list").style.backgroundColor ='white';
    document.querySelector(".render-service-text").style.color ='grey';
    

    if(location.pathname === '/error-credit-removal' || location.pathname === '/services'){
        document.querySelectorAll(".render-servise-list").forEach(element => {element.style.backgroundColor ='white';});
        document.querySelectorAll("render-servise-text").forEach(element => {element.style.color ='grey';});;
        document.querySelector("#blue").style.backgroundColor ='#F1B0FF';
        document.querySelector("#one").style.color ='white';
        document.querySelector("#imageone").style.filter='brightness(0) invert(1)';
     }
  })

  const creditrepair = () => {
     
    const store = [
        {logo:"Bank Card Missing",
        title:"Error Credit Removal",
        link:'./error-credit-removal#section1',
        color: 'blue',
        text: 'one',
        image: 'imageone'
        },
        {logo:"Bank Cards",
        title:"Auto Credit Repair",
        link:'./auto-credit-repair#section2',
        color: 'red',
        text:'two',
        image: 'imagetwo'
        },
        {logo:"home",
        title:"Home Buying Credit Repair",
        link:'./home-buying-credit-repair#section3',
        color: 'green',
        text:'three',
        image: 'imagethree'
        },
        {logo:"bank cruptcy",
        title:"Bankruptcy Credit Repair",
        link:'./bankcruptcy-credit-repair#section4',
        color: 'grey',
        text:'four',
        image: 'imagefour'
        },
        {logo:"Identity Theft",
        title:"Identity Theft Credit Repair",
        link:'./identity-theft-credit-repair#section5',
        color: 'yellow',
        text:'five',
        image: 'imagefive'
        },
        {logo:"autharized",
        title:"Authorized Users Service",
        link:'./authorized-users-service#section6',
        color: 'orange',
        text:'six',
        image: 'imagesix'
        },
    
      ]
      
      return (
        <>
          <Helmet>
                <meta charSet="utf-8" name="description" content="Get the best credit repair & restoration services from consultation with specialists. We help consumers to raise their credit scores and qualify for more affordable loans."/>
                <title>Get Credit Repair Service With Valuable Tips & Tricks From Specialists</title>
                <link rel="canonical" href="http://mysite.com/example" />
              </Helmet>
        <div className="main-div">
             <div className="sub-div1">
                {store.map((data, unique) => {
                    return (
                        <>
                        <HashLink smooth  to={`/${data.link}`}  key={unique} style={{ textDecoration: 'none' , width: '400px' }}>
                            <div id={data.color} className="render-servise-list changes-hover hover-container" >
                                <div className='render-service-logo'>
                                   <img id={data.image} className='img-service-list changes-logo' src={require("../../assets/images/"+data.logo+'.svg')}/>
                            
                               </div>
                                <div id={data.text} className='render-service-text changes-text'>
                                     {data.title}
                                </div>
                            </div>
                        </HashLink>
                        </>
                    )
                })}
                <div className='line'>

                </div>
                <div className='tag-h4'>
                    <h4>Ready To Take Action?</h4>
                </div>
                <div className='contain-img-text'>
                    <img src={serviceaction}/>
                    <div className='img-text'>
                        <p>Dont try self-help credit repair, let our experts fight for you.</p>
                        <NavLink to='/Getstarted'>
                        <button>Start Now</button>
                        </NavLink>
                    </div>
                </div>
             </div>
             
             <div className="sub-div" id='section1'>
                <div className="sidetext">
                   <h1>Error Credit Removal</h1>
                   <img  src={frame}/>
                   <p>
                   There may be one error, or many errors that are reporting on your credit report.  Over 40 
                   million people in the US have reporting discrepancies that continue to drop the credit scores.
                      One late payment could cause 100 point credit score drop.  We look for these errors when the
                       creditors report the data and challenge all negative and incorrect information. 
                   </p>
                   <p>
                   We challenge these accounts at a different angle with the credit bureaus and the creditors. 
                   Each phase is 30 days for results from the credit bureaus. We may need to negotiate and settle 
                   with the creditors to avoid or stop litigation. Additional fees may apply.
                   </p>
                </div>

            
             </div>
    
        </div>
    </>
      )
   
  }

  return (
    <>
   <ScrollToTopOnMount/>
    <Container className="header-image" fluid>
        <Row className="serviceimages">
            <Col xs={12} md={6} lg={6} className="colimage align-middle">
                <Row >
                <h2 className="text"><span>Our</span> Credit Restoration <span>Services</span></h2>
                </Row>
                <Row className="coltext">
               <p>For 25 years, April’s Credit Repair has helped restore thousands of satisfied customers. 
               Don’t let self-help credit repair ruin your chances of getting your credit back to where you want it to be. Let our experts help</p>
                </Row>
            </Col>
            <Col xs={12} md={6} lg={6}>
               <Image className="main-service-image"  width={"70%"} height={"auto"} src={hand}/>
            </Col>
        </Row>
    </Container>

  <div className='main-services'>
  
       {creditrepair()}
       <div className="dotoneservices"></div>
      <div className="dottwoservices"></div>
      <div className="dotthreeservices"></div>
      {/* <div className="dotfourservices"></div> */}
      <div className="dotfiveservices"></div>
      
  </div>
    
    </>
  )
}



export default Error_Credit_Removal