import React , {useState, useEffect} from 'react'
import '../../styles/home.css'
import "../../styles/Navbar.css"
import "../../styles/Error_Credit_Removal.css"
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Image from "react-bootstrap/Image";
import hand from '../../assets/images/hand.png'
import serviceaction from '../../assets/images/service-action.png'
import frame from '../../assets/images/Frame 69.png'
import Navbar from '../Navbar';
import { NavLink, useLocation} from "react-router-dom";
import { HashLink } from 'react-router-hash-link';
import {Helmet} from "react-helmet";


function ScrollToTopOnMount() {
    useEffect(() => {
      window.scrollTo(850, 850);
    }, []);
  
    return null;
  }

function Auto_Credit_Repair() {

    const location = useLocation()

    useEffect(() => {
        console.log(location.pathname , 'services-------')
    
        document.querySelector(".render-servise-list").style.backgroundColor ='white';
        document.querySelector(".render-service-text").style.color ='#ABABAB';
        // document.querySelector(".img-service-list").style.filter='brightness(0) invert(1)';
    
        if(location.pathname === '/auto-credit-repair'){
            document.querySelectorAll(".render-servise-list").forEach(element => {element.style.backgroundColor ='white';});
            document.querySelectorAll("render-servise-text").forEach(element => {element.style.color ='grey';});;
            document.querySelector("#red").style.backgroundColor ='#C4B8FE';
            document.querySelector("#two").style.color ='white';
            document.querySelector("#imagetwo").style.filter='brightness(0) invert(1)';
           
         }
      })

    const creditrepair = () => {
     
        const store = [
            {logo:"Bank Card Missing",
            title:"Error Credit Removal",
            link:'./error-credit-removal#section1',
            color: 'blue',
            text: 'one',
            image: 'imageone'
            },
            {logo:"Bank Cards",
            title:"Auto Credit Repair",
            link:'./auto-credit-repair#section2',
            color: 'red',
            text:'two',
            image: 'imagetwo'
            },
            {logo:"home",
            title:"Home Buying Credit Repair",
            link:'./home-buying-credit-repair#section3',
            color: 'green',
            text:'three',
            image: 'imagethree'
            },
            {logo:"bank cruptcy",
            title:"Bankruptcy Credit Repair",
            link:'./bankcruptcy-credit-repair#section4',
            color: 'grey',
            text:'four',
            image: 'imagefour'
            },
            {logo:"Identity Theft",
            title:"Identity Theft Credit Repair",
            link:'./identity-theft-credit-repair#section5',
            color: 'yellow',
            text:'five',
            image: 'imagefive'
            },
            {logo:"autharized",
            title:"Authorized Users Service",
            link:'./authorized-users-service#section6',
            color: 'orange',
            text:'six',
            image: 'imagesix'
            },
        
          ]
          return (
            <>
            <div className="main-div">
            
                 <div className="sub-div1">
                    {store.map((data, dex) => {
                        return (
                            <>
                            <HashLink smooth to={`/${data.link}`}  key={dex} style={{ textDecoration: 'none' }}>
                            <div  id={data.color} className="render-servise-list changes-hover hover-container" >
                                <div className='render-service-logo'>
                                   <img id={data.image} className='img-service-list changes-logo' src={require("../../assets/images/"+data.logo+'.svg')}/>
                                </div>
                                <div id={data.text} className='render-service-text changes-text'>
                                     {data.title}
                                </div>
                            </div>
                            </HashLink>
                            </>
                        )
                    })}
                    <div className='line'>
    
                    </div>
                    <div className='tag-h4'>
                        <h4>Ready To Take Action?</h4>
                    </div>
                    <div className='contain-img-text'>
                        <img src={serviceaction}/>
                        <div className='img-text'>
                            <p>Dont try self-help credit repair, let our experts fight for you.</p>
                            <NavLink to='/Getstarted'>
                              <button>Start Now</button>
                           </NavLink>
                        </div>
                    </div>
                 </div>
                 <div className="sub-div">
                    <div className="sidetext" id='section2' >
                       <h1>Auto Credit Repair</h1>
                       <img  src={frame}/>
                       <p>
                       Do you need to get pre-approved to purchase a vehicle, but your scores are too low
                        to get the best interest rate? Let us review your credit reports and raise your scores
                         before going into a loan and save you thousands. 
                       </p>
                       <p>
                     
                       </p>
                    </div>
    
    
    
                 </div>
            </div>
        </>
          )
       
      }
  return (
    <>
    <ScrollToTopOnMount/>
            <Helmet>
                <meta charSet="utf-8" name="description" content="Do you need an auto credit repair service or auto loans but have a bad credit score? Let us review your credit reports and raise them to enable you to get a reasonable auto loan."/>
                <title>Auto Credit Repair Service | Auto Repair Loans For Bad Credit</title>
                <link rel="canonical" href="http://mysite.com/example" />
              </Helmet>
         <Container className="header-image" fluid>
        <Row className="serviceimages">
            <Col xs={12} md={6} lg={6} className="colimage align-middle">
                <Row >
                <h2 className="text"><span>Our</span> Credit Restoration <span>Services</span></h2>
                </Row>
                <Row className="coltext">
               <p>For 25 years, April’s Credit Repair has helped restore thousands of satisfied customers. 
               Don’t let self-help credit repair ruin your chances of getting your credit back to where you want it to be. Let our experts help</p>
                </Row>
            </Col>
            <Col xs={12} md={6} lg={6}>
               <Image className="main-service-image"  width={"70%"} height={"auto"} src={hand}/>
            </Col>
        </Row>
    </Container>

  <div className='main-services'>
       {creditrepair()}
       <div className="dotoneservices"></div>
      <div className="dottwoservices"></div>
      <div className="dotthreeservices"></div>
      <div className="dotfourservices"></div>
      <div className="dotfiveservices"></div>
  </div>
    
    </>
  )
}

export default Auto_Credit_Repair