
import './App.css';
import Navbar from './components/Navbar';
import Home from './components/Home';
import Error_Credit_Removal from './components/Services/Error_Credit_Removal';
import Authorized_Users_Service from './components/Services/Authorized_Users_Service';
import Auto_Credit_Repair from './components/Services/Auto_Credit_Repair';
import Bankcruptcy_Credit_Repair from './components/Services/Bankcruptcy_Credit_Repair';
import Home_Buying_Credit_Repair from './components/Services/Home_Buying_Credit_Repair';
import Identity_Theft_Credit_Repair from './components/Services/Identity_Theft_Credit_Repair';
import Faq from './components/Faqpage/Faqpage';
import Contact from './components/ContactUs/ContactUs';
import Resources from './components/Resources/Resources';
import Pricing from './components/Pricing/Pricing';
import Callbutton from './components/Callbutton';
import Services from './components/Services/Services';
import ScrollToTop from './components/ScrollToTop';

import Contactformnext from './components/Contactformnext';

import {
  BrowserRouter,
  Switch,
  Route,
  Routes,
  Redirect,
  useLocation,
  useParams,

} from "react-router-dom";
import Footer from './components/Footer';
import Litigation from './components/Litigation/Litigation';
import Contactform from './components/Contactform';
import Thankyou from './components/Thankyou';




function App() {
  
  return (
    <div className="App">

      <BrowserRouter>
      <div className="App" style={{ backgroundImage: "url(./circle.jpg)" }}>
   
      <Navbar/>
      <Callbutton/>
        <Routes>
        
          {/* <Route exact path="/" element={<Home/>} /> */}
           
          <Route exact path="/" element={<Home/>} />
          <Route path="/error-credit-removal" element={<Error_Credit_Removal/>} />
          <Route path="/authorized-users-service" element={<Authorized_Users_Service/>} />
          <Route path="/bankcruptcy-credit-repair" element={<Bankcruptcy_Credit_Repair/>} />
          <Route path="/auto-credit-repair" element={<Auto_Credit_Repair/>} />
          <Route path="/home-buying-credit-repair" element={<Home_Buying_Credit_Repair/>} />
          <Route path="/identity-theft-credit-repair" element={<Identity_Theft_Credit_Repair/>} />
          <Route path="/faq" element={<Faq/>} />
          <Route path="/contact-us" element={<Contact/>} />
          <Route path="/GetStarted" element={<Contactform/>} />
          <Route path="/resources" element={<Resources/>} />
          <Route path="/pricing" element={<Pricing/>} />
          <Route path="/litigation" element={<Litigation/>} />
           <Route path="/services" element={<Services/>}/>
          <Route path="/Thankyou" element={<Thankyou/>} />

        </Routes>
     <Footer /> 

      
      </div>
    
    </BrowserRouter>


    </div>
  );
}


export default App;
