import React from 'react'
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Pricingimage from '../../assets/images/Pricing.png';
import ScrollToTop from '../ScrollToTop';
import '../../styles/Pricing.css';
import '../../styles/home.css'

import { NavLink } from "react-router-dom";

import Image from "react-bootstrap/Image";
import {Helmet} from "react-helmet";

const Pricing = () => {
  return (
  <>
  <ScrollToTop/>
  
             <Helmet>
                <meta charSet="utf-8" name="description" content="April's Credit Repair offers you low monthly plans to help our clients build a good credit history for 6months. It includes $399.00 Per Administrative Services, $149.00 Per month, $55.00 Per Creditor Settlement, $695.00 Per 1st Litigation Settlement- $595 per 2nd Settlement- $495 per 3rd Settlement (Lawsuits-Attorneys)"/>
                <title>Pricing April's Credit Repair | Monthly Payment Plans</title>
                <link rel="canonical" href="http://mysite.com/example" />
              </Helmet>
    <Container  className="header-image-litigation container-center-horizontal" fluid>
        <Row className="restorelitigationprice">
            <Col xs={12}  md={6} lg={6} className="coliimage-litigation align-middle">
                <Row >
                <div className="text-centerhome">
                     <p><span>Low Monthly </span></p>
                      <p>Payment</p>
                     <p><span>Plans</span></p> 
               </div>
                </Row>
                <Row className="coltextlitigation">
               <p>Included in the credit repair fee: We help our clients build a good 
               credit history for 6 months. We educate client on what they need to do and which accounts to open.
            </p>
                </Row>

                <Row>
                <div className="btn-consulation">

                   <NavLink to='/Getstarted'>
                    <button type="button" className="btn btn-info">Free Online Consultation</button>
                 </NavLink>
              </div>
                </Row>
            </Col>
            <Col xs={12} md={6} lg={6} className="imagePrice">
               <Image  width={"100%"} height={"auto"} mt-2 src={Pricingimage}/>
            </Col>
        </Row>
    </Container>

    <Container>
        <Row>
            <Col xs={12} md={12} lg={12} className="main-pricingcontainer">
                 <div className="startheading">
                     <h1>Aprils Credit Repair Fees</h1>
                 </div>
                 <div className="description-price">
                  <p>We are licensed and bonded with State and Federal laws, and offer low monthly
                   payment plans for up to 6 months.</p>
                 </div>
                 <div className="description-pricedetail">
                     <p>
                     Credit Repair Fee: $399.00 for Administrative Services, 
                     includes first phase of challenges and education.  Monthly 
                     fee per month is $149/mo in 30-45 days for up to 6 months if needed.
                      We have discounts for couples and members of military.
                     </p>
                     <p>
                     Credit Building Education: Included in the credit repair fee:
                      We help our clients build a good credit history for 6 months.
                       We educate client on what they need to do and which accounts
                        to open. Often times, helping them acquire up to 10k+ in individual
                     unsecured credit lines (guaranteed approval) even with low scores. 
                     These credit builder accounts are designed for credit building and
                      making small monthly payments to generate a good score. Along with 
                      that valuable information, we teach them how to maintain their good credit.
                     </p>
                     <p>
                     Tradelines: We have 500+ card holders each month, with credit cards ranging from $5k-$50k.
                      All of these cards are 15% balances or less with good activity and history. Prices range 
                      from $300-$800 and last for 30 days. We only do this if the client is in a contact or going 
                      into a contract for a home loan or refinancing. Adding AU's if client needs a quick 15-50 
                      points in 8 days after the statement date of the card.
                     </p>
                 </div>
            </Col>

        </Row>
    </Container>

    <Container>
        <Row>
          
                
            <Col xs={12} md={12} lg={12} className="main-pricingcontainer">
                 <div className="startheading-plans">
                     <h3>Plans and Pricing</h3>
                     <h6>
                     Offer Low Monthly Payment Plans
                     </h6>
                     <p>
                     $399.00 Per Administrative Services 
                     </p>
                     <p>
                     $149.00 Per month - 6 Month Agreement - Client can cancel any time before the 6-month completion.

                     </p>
                     <p>
                     $55.00 Per Creditor Settlement- First settlement is free 


                     </p>

                     <p>
                     $695.00 Per 1st Litigation Settlement- $595 per 2nd Settlement- $495 per 3rd Settlement (Lawsuits-Attorneys)
                     </p>
                 </div>

                 <div>
                  
                 </div>
            
      <div className="dotoneprice"></div>
      <div className="dottwoprice"></div>
      <div className="dotthreeprice"></div>
      <div className="dotfourprice"></div>
      <div className="dotfiveprice"></div>
         
            </Col>
        </Row>
    </Container>
  </>
  )
}

export default Pricing 