
import React , {useState, useEffect, useRef} from 'react'
import '../../styles/home.css'
import "../../styles/Navbar.css"
import "../../styles/Error_Credit_Removal.css"

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Image from "react-bootstrap/Image";
import hand from '../../assets/images/hand.png'
import serviceaction from '../../assets/images/service-action.png'
import frame from '../../assets/images/Frame 65.png'
import { NavLink, useLocation } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';
import {Helmet} from "react-helmet";


function ScrollToTopOnMount() {
    useEffect(() => {
      window.scrollTo(850, 850);
    }, []);
  
    return null;
  }


function Authorized_Users_Service() {
    
    const location = useLocation()

    const servicesection = useRef(null)

    const gotoservicesection = () => window.screen({
        top:window.servicesection.current.offsetTop,
        behavior:"smooth"
    })

    useEffect(() => {
        console.log(location.pathname , 'services-------')
    
        document.querySelector(".render-servise-list").style.backgroundColor ='white';
        document.querySelector(".render-service-text").style.color ='#ABABAB';
        // document.querySelector(".img-service-list").style.filter='brightness(0) invert(1)';
    
        if(location.pathname === '/authorized-users-service'){
            document.querySelectorAll(".render-servise-list").forEach(element => {element.style.backgroundColor ='white';});
            document.querySelectorAll("render-servise-text").forEach(element => {element.style.color ='grey';});;
            document.querySelector("#orange").style.backgroundColor ='#F1B0FF';
            document.querySelector("#six").style.color ='white';
            document.querySelector("#imagesix").style.filter='brightness(0) invert(1)';
         }
      })

    const creditrepair = () => {
     
        const store = [
            {logo:"Bank Card Missing",
            title:"Error Credit Removal",
            link:'./error-credit-removal#section1',
            color: 'blue',
            text: 'one',
            image: 'imageone'
            },
            {logo:"Bank Cards",
            title:"Auto Credit Repair",
            link:'./auto-credit-repair#section2',
            color: 'red',
            text:'two',
            image: 'imagetwo'
            },
            {logo:"home",
            title:"Home Buying Credit Repair",
            link:'./home-buying-credit-repair#section3',
            color: 'green',
            text:'three',
            image: 'imagethree'
            },
            {logo:"bank cruptcy",
            title:"Bankruptcy Credit Repair",
            link:'./bankcruptcy-credit-repair#section4',
            color: 'grey',
            text:'four',
            image: 'imagefour'
            },
            {logo:"Identity Theft",
            title:"Identity Theft Credit Repair",
            link:'./identity-theft-credit-repair#section5',
            color: 'yellow',
            text:'five',
            image: 'imagefive'
            },
            {logo:"autharized",
            title:"Authorized Users Service",
            link:'./authorized-users-service#section6',
            color: 'orange',
            text:'six',
            image: 'imagesix'
            },
        
          ]
          return (
            <>
            <div className="main-div">
                 <div className="sub-div1">
                    {store.map((data, ind) => {
                        return (
                            <>
                            <HashLink smooth to={`/${data.link}`}  key={ind} style={{ textDecoration: 'none' }}>
                            <div id={data.color} className="render-servise-list changes-hover hover-container" >
                                <div className='render-service-logo'>
                                   <img id={data.image} className='img-service-list changes-logo' src={require("../../assets/images/"+data.logo+'.svg')}/>
                                </div>
                                <div id={data.text} className='render-service-text changes-text'>
                                     {data.title}
                                </div>
                            </div>
                            </HashLink>
                            </>
                        )
                    })}
                    <div className='line'>
                        
                    </div>
                    <div className='tag-h4'>
                        <h4>Ready To Take Action?</h4>
                    </div>
                    <div className='contain-img-text'>
                        <img src={serviceaction}/>
                        <div className='img-text'>
                            <p>Dont try self-help credit repair, let our experts fight for you.</p>
                            <NavLink to='/Getstarted'>
                               <button>Start Now</button>
                           </NavLink>
                        </div>
                    </div>
                 </div>
                 <div className="sub-div"   id='section6'>
                    <div className="sidetext">
                    <h1>Authorized Users Service</h1>
                       <img  src={frame}/>
                       <p>
                       We have 500+ card holders each month, with credit cards ranging from $1k-$50k.
                        All  cards have 15% balances or less with great activity and history. 
                        Prices range from $300-$800 and will stay on your report for 30 days. 
                        Extensions also available.  We will review your current reports to determine 
                        the best tradelines for your situation. 
                       </p>
                       <p>
                       The credit repair process can be hard to understand but 
                       at Aprils’s Credit Repair we make it easy on you by utilizing
                        our years of experience to provide to you the best possible out 
                        come to your credit standing. After a short period of time with us,
                         you will see results that restore your quality of life, your credit
                          and your peace of mind.
                       </p>
                    </div>
               </div>
            </div>
        </>
          )
       
      }
    

  return (
    <>
  <ScrollToTopOnMount/>
            <Helmet>
                <meta charSet="utf-8" name="description" content="We provide the authorized to users and suggest the best possible way to come out to your credit standing. After a short period with us, you will see results restoring your quality of life, credit, and peace of mind."/>
                <title>Get Authorized Users Service | Credit Repair Service</title>
                <link rel="canonical" href="http://mysite.com/example" />
              </Helmet>
    <Container className="header-image" fluid>
        <Row className="serviceimages">
            <Col xs={12} md={6} lg={6} className="colimage align-middle">
                <Row >
                <h1 className="text"><span>Our</span> Credit Restoration <span>Services</span></h1>
                </Row>
                <Row className="coltext">
               <p>For 25 years, April’s Credit Repair has helped restore thousands of satisfied customers. 
               Don’t let self-help credit repair ruin your chances of getting your credit back to where you want it to be. Let our experts help</p>
                </Row>
            </Col>
            <Col xs={12} md={6} lg={6}>
               <Image className="main-service-image"  width={"70%"} height={"auto"} src={hand}/>
            </Col>
        </Row>
    </Container>

  <div className='main-services'>
       {creditrepair()}
       <div className="dotoneservices"></div>
      <div className="dottwoservices"></div>
      <div className="dotthreeservices"></div>
      <div className="dotfourservices"></div>
      <div className="dotfiveservices"></div>
  </div>
    </>
  )
}

export default Authorized_Users_Service