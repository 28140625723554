import React from 'react'
import {Helmet} from "react-helmet";

function Faq({faq, index, toggleFAQ}) {
  return (
    <div
			className={"faq " + (faq.open ? 'open' : '')}
			key={index}
			onClick={() => toggleFAQ(index)}
		>

          
			<div className="faq-question">
				<h6>{faq.question}</h6>
			</div>
			<div className="faq-answer">
				<p>{faq.answer}</p>
			</div>
		</div>
  )
}

export default Faq