import React from 'react'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button'


function Thankyou() {
  return (

          <>
           <Container className="contactformnext align-items-center justify-content-center" fluid>
             <Row className="contacthankyou">
             <Row>
               <Col xs={12} md={12} className="thankcol">
             
               <h1>Congratulations !</h1> 
               <p>A Credit Repair Professional will be calling you shortly to provide a free, no-obligation consultation.
                You’re Qualified For A Free Consultation. We are calling from a 949#</p>
               <p>Thank you for your interest. Follow the steps below.</p>
               </Col>
             </Row>
             <Row>
               <Col xs={12} md={12} className="thankcol">
               <p>1) Look for a detailed email in the next few minutes explaining “How we do it”. See our Reviews.</p>
               </Col>
              </Row>
              <Row>
              <Col xs={12} md={12} className="thankcol">
             <p>2) Answer our call. One of our Senior Credit Professionals will be calling you from a 949 area code and will help you to provide a free, no-obligation consultation.</p>
               </Col>
              </Row>
              <Row>
              <Col xs={12} md={12} className="thankcol">
             <p>3) Why wait? Call us now. Our Senior Credit Experts will provide a FREE 20 minute consultation.</p>
               </Col>
              </Row>
             <Row>
             <Col xs={12} md={12} className="thankcol thankcolbtn">
                <a href="tel:(949) 444-5675">
                   <Button className="contactformbtn">Call (949) 444-5675</Button>
                </a>
               
               </Col>
             </Row>
             </Row>
           </Container>
          </>
        )
      }
 

export default Thankyou