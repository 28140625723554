import React from 'react'
import '../styles/Callbutton.css'
import { NavLink } from "react-router-dom";
import call from '../assets/images/call.png';

function Callbutton() {
  return (
    <div>
    {/* //  <div className="mybutton">
    //  <button className="feedback">
    //  <span><img src={call}/></span>
    //  </button>

    //  </div> */}

     <button className="mybutton">  <span><img src={call}/></span>
        <a href="tel:(949) 444-5675"> <span className="feedback">Call us now ?</span></a>
    </button>
    </div>
  )
}

export default Callbutton