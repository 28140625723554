import React from 'react'
import '../../styles/Litigation.css';
import '../../styles/home.css'

import ScrollToTop from '../ScrollToTop';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import litigation1 from "../../assets/images/litigation 1.png";
import avoid from "../../assets/images/avoid litigation.png";
import keepgood from '../../assets/images/keep good.png';
import Onetime from '../../assets/images/onetime.png';
import legal from '../../assets/images/legalaction.png';
import { NavLink } from "react-router-dom";
import isstock from '../../assets/images/iStock.png'
import {Helmet} from "react-helmet";

import Image from "react-bootstrap/Image";


function Litigation() {
  return <Litigationchild {...litigationData} />
}

export default Litigation



const Litigationchild = (props) => {
   
    const {
      logoAprilsCredit1,
      spanText1,
      spanText2,
      spanText3,
      spanText4,
      spanText5,
      spanText6,
      spanText7,
      line1,
      helpYouBannerContent,
      spanText8,
      spanText9,
      spanText10,
      spanText11,
      spanText12,
      spanText13,
     
      spanText14,
      spanText15,
      x51,
      x61,
      spanText16,
      spanText17,
      spanText18,
      spanText19,
      x8PngCopy1,
      x71,
      spanText20,
      spanText21,
      coloredDots,
      group19,
      spanText22,
      spanText23,
      spanText24,
      image2,
      spanText25,
      spanText26,
      line31,
      phone,
      spanText27,
      email,
      spanText28,
      locationOn,
      spanText29,
      spanText30,
      line32,
      frame52,
      line2,
      spanText31,
      spanText32,
      spanText33,
    } = props;
  
    return (
        <>
 <ScrollToTop/>
               <Helmet>
                <meta charSet="utf-8" name="description" content="April's Credit Repair helps you settle your unpaid debt and avoid litigation, judgments, and liens. We will work to keep your good accounts open and keep your credit scores in a positive place."/>
                <title>Consumer Credit Litigation | April's Credit Repair</title>
                <link rel="canonical" href="http://mysite.com/example" />
              </Helmet>
   <Container  className="header-image-litigation container-center-horizontal" fluid>
        <Row className="restorelitigation">
            <Col xs={12}  md={6} lg={6} className="coliimage-litigation align-middle">
                <Row >
                <div className="text-centerhome">
                     <p><span>Help </span>You </p>
                      <p>To Settle on</p>
                     <p><span>Unpaid Debt</span></p> 
               </div>
                </Row>
                <Row className="coltextlitigation">
               <p>Aprils Credit Repair can help you settle on your unpaid debt, and immediate reduction of payments to the creditors. 
               We can help you avoid any litigation, judgements, and liens. Unlike other settlement companies, we will work to keep your good accounts open 
               and keep your credit scores in a positive place.
            </p>
                </Row>
            </Col>
            <Col xs={12} md={6} lg={6} className="imagelitigation-banner">
               <Image  width={"100%"} height={"auto"} mt-2 src={litigation1}/>
            </Col>
        </Row>
    </Container>

      <div className="container-center-horizontal restorelitigation">
        <div className="litigationscreen" >
          <Container className="flex-col">
            <div className="flex-row">
              <div className="frame-62">
                <div className="help-you-avoid-any-litigationvalign-text-bottompoppins-medium-heavy-metal-36px textalign-left">
                  <span>
                    <h1 className="poppins-medium-heavy-metal-36px ">{spanText14}</h1>
                  </span>
                </div>
                <div className="aprils-credit-repair-1valign-text-bottompoppins-normal-sonic-silver-20px textalign-left">
                  <span>
                    <span className="poppins-normal-sonic-silver-20px ">{spanText15}</span>
                  </span>
                </div>
              </div>
              <div className="overlap-group">
                <div className="ellipse-10"></div>
                <img className="x5-1" src={avoid} />
              </div>
            </div>
            <div className="flex-row-1">
              <div className="overlap-group1">
                <div className="ellipse-11"></div>
                <img className="x6-1" src={keepgood} />
              </div>
              <div className="frame-63">
                <div className="keep-good-credit-cards-openvalign-text-bottompoppins-medium-heavy-metal-36px ">
                  <span>
                    <span className="poppins-medium-heavy-metal-36px textalign-left">{spanText16}</span>
                  </span>
                </div>
                <div className="many-settlement-comppoppins-normal-sonic-silver-20px textalign-left">
                  <span className="poppins-normal-sonic-silver-20px ">{spanText17}</span>
                </div>
              </div>
            </div>
            <div className="flex-row-2">
              <div className="frame-64">
                <div className="charge-a-one-time-paymentvalign-text-bottompoppins-medium-heavy-metal-36px textalign-left">
                  <span>
                    <span className="poppins-medium-heavy-metal-36px">{spanText18}</span>
                  </span>
                </div>
                <div className="we-charge-a-one-timepoppins-normal-sonic-silver-20px textalign-left">
                  <span className="poppins-normal-sonic-silver-20px">{spanText19}</span>
                </div>
              </div>
              <div className="overlap-group6">
                <div className="ellipse-12"></div>
                <img className="x8png-copy-1" src={Onetime} />
              </div>
            </div>
            <div className="flex-row-3">
           
            <div className="ellipse-13">
            <img className="x7-1" src={legal} />
            </div>
            <div className="frame-65">
              <div className="resolving-debt-legal-actionvalign-text-bottompoppins-medium-heavy-metal-36px textalign-left">
                <span>
                  <span className="poppins-medium-heavy-metal-36px">{spanText20}</span>
                </span>
              </div>
              <div className="it-is-time-to-startpoppins-normal-sonic-silver-20px-2 textalign-left">
                <span className="poppins-normal-sonic-silver-20px-2">{spanText21}</span>
              </div>
            </div>
          </div>
          </Container>

          <div className='take-action-litigation'>
          <img  src={isstock} alt="imglitigation" className='imagelitigation'/>
          <div className='taketestlit'>
              <h4>Ready To </h4>
              <h4>Take Action?</h4>
              <p>Dont try self-help credit repair, let our experts fight for you</p>
              <NavLink to="/GetStarted">  <button>Start now</button></NavLink>
          </div>
     </div> 

     <div className="dotone"></div>
      <div className="dottwo"></div>
      <div className="dotthree"></div>
      <div className="dotfour"></div>
      <div className="dotfive"></div>
         
         
        </div>
      </div>
      </>
    );
    
  }
  
  const litigationData = {
    logoAprilsCredit1: "logo-aprils-credit-1-1.png",
    spanText1: "Home",
    spanText2: "Our services",
    spanText3: "FAQ",
    spanText4: "Resources",
    spanText5: "Litigation",
    spanText6: "Contact us",
    spanText7: "    (949) 444-5675",
    line1: "line-1-1.png",
    helpYouBannerContent: "rectangle-33-1.png",
    spanText8: "Help",
    spanText9: " ",
    spanText10: "to Settle on Your",
    spanText11: " ",
    spanText12: "Unpaid Debt",
    spanText13:
      "Aprils Credit Repair can help you settle on your unpaid debt, and immediate reduction of payments to the creditors.  We can help you avoid any litigation, judgements, and liens. Unlike other settlement companies, we will work to keep your good accounts open and keep your credit scores in a positive place.",
    
    spanText14: "Help you Avoid any Litigation...",
    spanText15:
      "Aprils Credit Repair can help you settle on your unpaid debt, and immediate reduction of payments to the creditors.  We can help you avoid any litigation, judgements, and liens. Unlike other settlement companies, we will work to keep your good accounts open and keep your credit scores in a positive place.",
    x51: "5-1-1.png",
    x61: "6-1-1.png",
    spanText16: "keep good credit cards open...",
    spanText17:
      "Many settlement companies will close your accounts and show a balance for up to 4 years as you pay off your debt in small monthly payments; along with their astronomical fees they charge for each account.  It is better to keep your good credit cards open. There are other options available to help you pay off your debt and raise your scores, instead of seeing your scores significantly drop due to closed accounts. We do not want you to lose your (age of accounts) and cause your scores to drop further.",
    spanText18: "Settlements...",
    spanText19:
      "Here is an Example, if you owe $20k in credit card debt and the accounts are in negative status, we can settle on these accounts for 10-60%. You can do a lump sum settlement, or a payment plan from 6-24 months. We want to avoid having the paid account show on your credit for the next 7 years.",
    x8PngCopy1: "8png-copy-1-1.png",
    x71: "7-1-1.png",
    spanText20: "Resolving Debt Legal Action...",
    spanText21:
      "It is time to start resolving this debt before legal action from these creditors/banks arise.  When this happens, this will resort to more fees, penalties, judgements, liens, and wage garnishment.  We are not attorneys, but we have been helping people for many years in your same situation, and our clients were able to avoid the thousands of dollars one would pay in attorney fees.  Please contact our office if you have been notified for any legal action or preferably before, if possible, for credit card or medical debt.  We can negotiate for you and put this behind you once and for all.",
    coloredDots: "colored-dots-1.png",
    group19: "istock-1202338962-1-1.png",
    spanText22: "Ready To Take Action?",
    spanText23: "Dont try self-help credit repair, let our experts fight for you.",
    spanText24: "Start now",
    image2: "image-2-1.png",
    spanText25:
      "For over 25 years April’s credit repair has helped restore and serve over 5,000 satisfied customers in Mission Viejo, California. Don’t let self-help credit repair ruin your chances of getting your credit back to where you want it to be.",
    spanText26: "Contact Us",
    line31: "line-3-1.png",
    phone: "phone-1.png",
    spanText27: "(949) 444-5675",
    email: "email-1.png",
    spanText28: "Info@AprilsCreditRepair.com",
    locationOn: "location-on-1.png",
    spanText29: (
      <React.Fragment>
        27201 Puerta Real Suite 300
        <br />
        Mission Viejo, CA 92691
      </React.Fragment>
    ),
    spanText30: "Follow us",
    line32: "line-3-1.png",
    frame52: "frame-52-1.png",
    line2: "line-2-1.png",
    spanText31: "©2022 April’s credit repair. All Rights Reserved",
    spanText32: "Website designed by ",
    spanText33: "Plaxonic",
  };