import React , {useEffect,useState} from 'react'
import '../styles/home.css'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import { NavLink, } from "react-router-dom";
import { MDBIcon} from 'mdbreact';
import ScrollToTop from '../components/ScrollToTop';
import Navbar from '../components/Navbar'

import card from "../assets/images/card 1.png"
import card2 from "../assets/images/card 2.png"
import card3 from "../assets/images/card 3.png"
import card4 from "../assets/images/card 4.png"

import istock from "../assets/images/iStock.png"
import Faq from "../components/Faq"

import call from "../assets/images/call.png"
import msg from "../assets/images/Vector (1).png"
import location from "../assets/images/Vector (2).png"
import Facebook from "../assets/images/Facebook.png"
import LinkedIn from "../assets/images/LinkedIn.png"
import Youtube from "../assets/images/YouTube.png"
import { act } from 'react-dom/test-utils';
import {Helmet} from "react-helmet";
// import {Tabtitle} from './utils/titlefun';

const Home = () =>{

  // Tabtitle("Get April's Credit Check | Generate Credit Report");

  const [active, setActive] = useState("btnone");
  const [imageClicked, setImageClicked] = useState({
    first: true,
    second: false,
    third: false,
    fourth: false,
  });



  //  useEffect(() => {
  //   const timer = setTimeout(() =>{
      
  //     setImageClicked({first:false, second:true , third: false,
  //     fourth: false,})
  //   }, 10000)

  //   clearTimeout(timer)

  //   const sectimer = setTimeout(() =>{
  //     setImageClicked({first:false, second:false , third: true,
  //       fourth: false,})
  //   }, 10000)

  //   clearTimeout(sectimer)
    
  //   const thetimer = setTimeout(() =>{
  //     setImageClicked({first:false, second:false , third: false,
  //       fourth: true,})
  //   }, 5000)

  //   clearTimeout(thetimer)


  //   const fortimer = setTimeout(() =>{
  //     setImageClicked({first:true, second:false , third: false,})
  //   }, 1000)




  //   return () => clearTimeout(fortimer);
  // })


var interval;
var image;

  useEffect(() => {
   
      document.querySelectorAll(".ground").forEach((ele) => {
        ele.style.color = "grey";
      });
      document.querySelector("." + active).style.color = "black";
     

      interval = setTimeout(() => {
        switch (active) {
          case "btnone":
            {setActive("btntwo");
            setImageClicked({
              first: false,
              second: true,
              third: false,
              fourth: false,
            })}
            break;
          case "btntwo":
            {setActive("btnthe"); 
              
            setImageClicked({
              first: false,
              second: false,
              third: true,
              fourth: false,
            })
          
             }
            break;
          case "btnthe":
            {setActive("btnfour");
            setImageClicked({
              first: false,
              second: false,
              third: false,
              fourth: true,
            })
          }
            break;

            case "btnfour":
            {setActive("btnone");
            setImageClicked({
              first: true,
              second: false,
              third: false,
              fourth: false,
            })
             }
            break;
  
          default:
            break;
        }
      }, 4000);

      return () => clearTimeout(interval);

    
  });

  
  const onClickHandler = (e, order) => {
    setActive(e.target.value);

  const resetImages = {
    first: false,
    second: false,
    third: false,
    fourth: false,
    };
    setImageClicked({
      ...resetImages,

      [order]: true
     
    })
    document.querySelector("imagehome").style.backgroundColor = "unset";
    ;
  };


  // useEffect(() => {
  //   if(active){
  //     document.querySelector("." + active).style.backgroundColor ='red';
  //   }
  // })



  // console.log(active)

    const [faqs, setfaqs] = useState([
        {
          question: "Why did you start April's Credit Repair?",
          answer: 'I started my company, because I was in the credit business for a long time, and I started helping my family and friends take control of their bad credit situations. It makes me Qso happy to help others and I know my purpose in life is to share my knowledge to help people, and I am passionate about it.',
          open: false
        },
        {
          question: 'How do I started?',
          answer: 'For more details, please look at FAQ ',
          open: false
        },
        {
          question: 'What is the Cost?',
          answer: 'This many.',
          open: false
        }
      ]);
 

  const store = [
      {logo:"Bank Card Missing",
       title:"Error Credit Removal",
       description:"There may be one error, or many errors that are reporting..",
       learnmore:"",
       color:"rgb(244,235,252)",
       link:'./error-credit-removal#section1'
       
    },
    {logo:"Bank Cards",
       title:"Auto Credit Repair",
       description:"Do you need to get pre-approved to purchase a vehicle..",
       learnmore:"",
       
       color:"rgb(237,239,254)",
       link:'./auto-credit-repair#section2'
    },
    {logo:"Home",
    title:"Home Buying Credit  Repair",
    description:"Is your credit ready to buy a new home or refinance your current..",
    learnmore:"",
    color:"rgb(244,235,252)",
    link:'./home-buying-credit-repair#section3'
    },

    {logo:"Merchant Account",
    title:"Bankruptcy Credit Repair",
    description:"Are you considering bankruptcy? Let us evaluate your debt..",
    learnmore:"",
    color:"rgb(244,235,252)",
    link:'./bankcruptcy-credit-repair#section4'
    },

    {logo:"Identity Theft",
    title:"Identity Theft Credit Repair",
    description:"Are you a victim of Identity Theft?  It is very important to..",
    learnmore:"",
    color:"rgb(237,239,254)",
    link:'./identity-theft-credit-repair#section5'
    },
    
    {logo:"Best Seller",
    title:"Authorized Users Service",
    description:"We have 500+ card holders each month, with credit cards..",
    learnmore:"",
    color:"rgb(244,235,252)",
    link:'./authorized-users-service#section6'
    },
    
  ]


  const toggleFAQ = index => {
    setfaqs(faqs.map((faq, i) => {
      if (i === index) {
        faq.open = !faq.open
      } else {
        faq.open = false;
      }

      return faq;
    }))
  }


 const imagetext = () => {
   return(
     <>
    <Container>
    <Row>
    <Col xs={12} md={5} lg={5}>
    <div className="restore-text">
          <div className="text-centerhome">
          <p><span>Restore </span>Your </p>
          <p>Credit The</p>
          <p><span>Right way </span></p> 
          </div>

          <div className="details">
             <p>For 25 years, April’s Credit Repair has helped restore thousands of satisfied customers. 
              Don’t let self-help credit repair ruin your chances of getting your credit back to where 
              you want it to be. Let our experts help</p>
          </div>
        <div className="btn-consulation">

        
      
       
        <NavLink to='/Getstarted'>
            <button type="button" className="btn btn-info">Free Online Consultation</button>
        </NavLink>
        </div>
        
        </div>
    </Col>
    <Col xs={12} md={7} lg={7}> 
    <div className="cardhome">
       <div className="image elementToFadeInAndOut">
       
          {imageClicked.first &&  <div className="five elementToFadeInAndOut"> <img  src={card} width={"80%"} alt="first" /> </div>}
        
      
          {imageClicked.second &&   <div className="six elementToFadeInAndOut"> <img  src={card2} width={"80%"} alt="second" /> </div>}
      
      
          {imageClicked.third &&   <div className="seven elementToFadeInAndOut"> <img  src={card3} width={"80%"} alt="third" /></div>}
  
         
          {imageClicked.fourth && <div className="eight elementToFadeInAndOut" > <img  src={card4} width={"80%"} alt="fourth" /></div>}
     
      </div>
      <div className="Ccontainer">
      <button
          value={"btnone"}
          onClick={(e) => onClickHandler(e, "first")}
          className="ground btnone"
        >
          01
        </button>
        <button
          value={"btntwo"}
          onClick={(e) => onClickHandler(e, "second")}
          className="ground btntwo"
        >
          02
        </button>
        <button
          value={"btnthe"}
          onClick={(e) => onClickHandler(e, "third")}
          className="ground btnthe"
        >
          03
        </button>
        <button
          value={"btnfour"}
          onClick={(e) => onClickHandler(e, "fourth")}
          className="ground btnfour"
        >
          04
        </button>
         {/* <img src={card} /> */}
        </div>
        </div>
    </Col>
       
       
  </Row>
    </Container>

     </>
   )
 }



 const allServices = () => {
    return(
        <>

 

      <Container className='services-container'>
       
     
         <Row xs={12} md={12} lg={12}>
          <div className='services-head'>
           <h6>Our Services</h6>
           <h1>Our Credit Restoration Services</h1>
           <p>Are you a victim of identity theft, errors on your credit report, recently came out of bankruptcy or simply looking to raise your score?
            We can help. For 25 years, April’s Credit Repair 
            has helped restore many families credit scores to get the rating they deserve.</p>
             
          </div> 
          </Row>

    <Row xs={12} md={6} lg={6}>
      <div className='services'>
        {store.map((data, index) => {
            return(
                <>
                <Col xs={12} md={6} lg={4} >
                <div className="render-container">
                    <div className='render'  key={index}
                         style={{
                           background: `${data.color}`,
                           }}>
                        <img className='img-services' src={require("../assets/images/"+data.logo+'.png')}/>
                    </div>
                    <div className="titleservices">
                     {data.title}
                    </div>
                    <div className='description-services'>
                     {data.description}
                    </div>
                   
                    <div className="btn-services">
                     <NavLink to={`/${data.link}`}  key={index} style={{ textDecoration: 'none' }}>
                     <button>Learn More</button>
                     </NavLink>
                    </div>
                    
                </div>
                </Col>
                </>
            )
        })}

       </div>
   </Row>       
</Container>
        </>
    )
}

const accordian  = () => {
   return (
   <>
    
  <Container className='faq-container' fluid>

      <div className="dotone"></div>
      <div className="dottwo"></div>
      <div className="dotthree"></div>
      <div className="dotfour"></div>
      <div className="dotfive"></div>
  
     <Row xs={12} md={12} lg={6}>
    

      <div className='faqs-head'>
        
          <NavLink to="/faq">
           <div className='faqs-head faqspagefull'>
                 <h3>Got Questions?</h3><span>Here are some popular questions and answers about our services  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;> </span>
           </div>
          </NavLink>
  
      </div>
     </Row>
    
  </Container>
   </>
   )
}

  return (
      <>

  
    <div className="restore-main-container">

             <Helmet>
                <meta charSet="utf-8" name="description" content="April's Credit Repair is the best credit repair agent & company for valuable tips to repair your credit score. Also, get the best solutions for credit restoration to qualify for more affordable loans"/>
                <title>Credit Repair Solutions & Agent | Tips By Credit Restoration Company</title>
                <link rel="canonical" href="http://mysite.com/example" />
              </Helmet>

    <ScrollToTop />
       
     {imagetext()}
   
   
     {allServices()}

     <div className='take-action'>
          <img  src={istock}/>
          <div className='taketest'>
              <h4>Ready To </h4>
              <h4>Take Action?</h4>
              <p>Dont try self-help credit repair, let our experts fight for you</p>
              <NavLink to="/GetStarted">  <button>Start now</button></NavLink>
          </div>
     </div> 
   

    </div>
    <div className='accordian-main'>
        {accordian()}
    </div>

 </>
 
  )
}


export default Home