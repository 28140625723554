import React , {useState} from 'react'
import '../styles/home.css'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import image2 from "../assets/images/image 2.png"
import call from "../assets/images/call.png"
import msg from "../assets/images/Vector (1).png"
import loc from "../assets/images/contactloc.png"
import Facebook from "../assets/images/Facebook.png"
import LinkedIn from "../assets/images/LinkedIn.png"
import Youtube from "../assets/images/YouTube.png"
import {useLocation} from 'react-router-dom';
import Instagram from "../assets/images/instagram (1).png"

function Footer() {
  // const location = useLocation()
  //   console.log(location.pathname , 'set---------')

  // if(location.pathname !== '/Resources') {}

  return (
    <>
  <Container className="footer-distributed">
   <Row className="borderlast">
   <Col xs={12} md={4} lg={4}>
   <div className="footer-left">

         <img src={image2}alt="logo"/>

         <p className="footer-links">
         For 25 years, April’s Credit Repair has helped restore thousands of satisfied customers. 
         Don’t let self-help credit repair ruin your chances of getting your credit back to where you 
         want it to be. Let our experts help.

         </p>

    </div>
   </Col>

   <Col xs={12} md={4} lg={4}>
   <div className="footer-center">
      <div>
         <p><span>Contact Us</span> </p>
     </div>
     
      <div className="call callnum">
          <p> <span> <img src={call}/></span>(949) 444-5675 </p>
      </div>

    <div className="call">
         <p><span><img src={msg}/></span><a href="mailto:Info@AprilsCreditRepair.com">Info@AprilsCreditRepair.com</a></p>
    </div>

    <div className="call">
         <p><span><img src={loc}/></span>27201 Puerta Real Suite 300
             Mission Viejo, CA 92691</p>
    </div>

 </div>
   </Col>

    <Col xs={12} md={4} lg={4}>
    <div className="footer-right">
      <p className="footer-company-about">
      <span>Follow Us</span>
    </p>
    <div className="footer-icons">
      <a href="https://www.facebook.com/aprilscreditrepair" target="_blank"><img src={Facebook}/></a>
      <a href="https://www.linkedin.com/in/april-kunysz-18536356?original_referer=http%3A%2F%2Faprilscreditrepair.com%2F" target="_blank"><img src={LinkedIn}/></a>
      <a href="https://www.instagram.com/aprilscreditrepair" target="_blank"><img src={Instagram}/></a>
   </div>

  </div>
    </Col>
   
  </Row>
  <Row>
  <Col className='lastcontent' xs={6} md={6} lg={12}>
       <Row className='copywrite'>©2022 April’s credit repair. All Rights Reserved</Row>
       <Row className='designedby'><p>Website designed by <a href='https://www.plaxonic.com/' target="_blank"><span>Plaxonic</span></a></p></Row>
    </Col>
  </Row>
 </Container>
    </>
  )

}

export default Footer