
import React ,{ useEffect, useState } from "react";
import logo from "../assets/images/logo (2).svg"
import call from '../assets/images/call.png';

import { NavLink, useLocation } from "react-router-dom";
import "../styles/Navbar.css"


const  Navbar = () => {
    const [isNavExpanded, setIsNavExpanded] = useState(false)
    const [active, setActive] = useState(null);

    const location = useLocation()
     
  
    useEffect(() => {
      //  console.log(location.pathname , 'bhbhb-------')
       document.querySelectorAll(".navlink").forEach((ele) => {
            ele.style.color = '#A5A5A5'
       })
       
       if(location.pathname === '/'){
          document.querySelector(".homeNavlink").style.color='black' ;
       }else if(location.pathname === '/error-credit-removal' || location.pathname === '/authorized-users-service'
       
      || location.pathname === '/bankcruptcy-credit-repair' || location.pathname === '/auto-credit-repair'  
      
      || location.pathname === '/home-buying-credit-repair' || location.pathname === '/identity-theft-credit-repair'
      
      ){
        document.querySelector(".servicesNavlink").style.color='black'
       }
      
       else if(location.pathname === '/faq'){
         document.querySelector(".faqLink").style.color='black';
       }

       else if(location.pathname === '/services'){
        document.querySelector(".servicesNavlink").style.color='black'
       }

       else if(location.pathname === '/contact-us'){
        document.querySelector(".contactusLink").style.color='black';
       }

       else if(location.pathname === '/resources'){
        document.querySelector(".resourcesLink").style.color='black';
       }

       else if(location.pathname === '/pricing'){
        document.querySelector(".PricingLink").style.color='black';
       }

       else if(location.pathname === '/litigation'){
        document.querySelector(".litigationLink").style.color='black';
       }
    
    })

    const changeNavState = (e) => {
     
      setIsNavExpanded(false)
      
    }

  return (
    <nav className="navigation">

      <NavLink to="/" className="brand-name" >
          <img src={logo} />
      </NavLink>
      
      <button className="hamburger"  onClick={() => {
           console.log("this is clicked---------")
          setIsNavExpanded(!isNavExpanded);
        }}>

        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-5 w-5"
          viewBox="0 0 20 20"
          fill="white"
          onClick={() => {
           console.log("this is clicked---------")
          setIsNavExpanded(!isNavExpanded);
        }}
        >
          <path
            fillRule="evenodd"
            d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM9 15a1 1 0 011-1h6a1 1 0 110 2h-6a1 1 0 01-1-1z"
            clipRule="evenodd"
          />
        </svg>
      </button>
      <div
       className={
          isNavExpanded ? "navigation-menu expanded" : "navigation-menu"
        }>
        <ul>
          <li onClick={changeNavState}>
           <NavLink className="navlink homeNavlink"  activeClassName="active" to="/">
              Home  
            </NavLink>
            
          </li>
          <li onClick={changeNavState}>
            <NavLink className="navlink servicesNavlink" to="/services">Our services</NavLink>
          </li>
          <li onClick={changeNavState}>
          <NavLink className="navlink faqLink" to="/faq">FAQ</NavLink>
        
          </li>
          <li onClick={changeNavState}>

           <NavLink className="navlink resourcesLink" to="/resources">Resources</NavLink>
           
          </li>
          

          <li onClick={changeNavState}>

            <NavLink className="navlink PricingLink" to="/pricing">Pricing</NavLink>

          </li>

          <li onClick={changeNavState}>

           <NavLink className="navlink litigationLink" to="/litigation">Litigation</NavLink>
           
          </li>
          <li onClick={changeNavState}>
            <NavLink className="navlink contactusLink" to="/contact-us">Contact us</NavLink>
          </li>
        </ul>
      </div>
      <div className="navigation-button">
   
      
        <NavLink to='/Getstarted'>
          <button type="button" className="btn btn-info cstm-consult">Free online Consultation</button>
        </NavLink> 
    
        
      </div>
    </nav>
  );
  
}

export default Navbar
