import React, {useState, useRef} from 'react'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Image from "react-bootstrap/Image";
import faq from '../assets/images/CONTACTUS 2.png'
import mail from '../assets/images/mail icon 3d 1.png'
import call from '../assets/images/contactcall.png'
import contactmsg from '../assets/images/contactmsg.png'
import contextloc from '../assets/images/contactloc.png' 
import Form from 'react-bootstrap/Form'
import { useLocation } from "react-router-dom";
import {Link, Routes, Route, useNavigate} from 'react-router-dom'
import emailjs from '@emailjs/browser';

import '../styles/home.css'
import '../styles/Faqpage.css'
import "../styles/Navbar.css"
import "../styles/Error_Credit_Removal.css"
import "../styles/ContactUs.css"
import { Button } from 'bootstrap';
import Contactformnext from './Contactformnext';


function Contactform() {
  
  const[showform, setShowForm] = useState(true)
  const [validated, setValidated] = useState(false);
  const [ text, setText] = useState(true);

  const[creditscore, setCreditscore] = useState();
  const[error, setError] = useState();
  const[service, setService] = useState();
    
  const [number, setNumber] = useState("");
  const navigate = useNavigate();

  const useform = useRef();


  const handleSubmitnext = () => {
    setShowForm(false);
    
  }

  
  const handleSubmit = (event) => {
    const form = event.currentTarget;


    event.preventDefault();
  
    if (form.checkValidity() === false) {
   
      event.stopPropagation();
    
    }

    setValidated(true);

    
   

    if(form.checkValidity() === true){
      setText(false)
      console.log("sending email")
      emailjs.sendForm('service_x9pyfcb', 'template_m0wuo8k', useform.current, 'gZPsg4RUdBhyz3W8i')
      .then((result) => {
      console.log("message sent successfully")
      
      }, (error) => {
       alert(error.message)
    });
    
    navigate('/Thankyou')

    }

    

    
    event.target.reset()
  };

  const checkInput = (e) => {
    const onlyDigits = e.target.value.replace(/\D/g, "");
    setNumber(onlyDigits);
  };

  

  return (
    <>
     {
       showform ? <>
      
       <Container className="contact-footer align-items-center justify-content-center contactsingleform" fluid>
       
       <h1>Credit Repair Made Easy</h1>
       <Form>
       <Row className="contact-footer-row">
         <h3>What Is Your Current Credit Scores?</h3>
         <Form.Select  aria-label="Default select example" required as="select" custom name="Credit_scores" onChange={(e) =>setCreditscore(e.target.value)}>
          
         <option value="" disabled selected>Select your Credit Scores</option>

          <option value="300-400">300-400</option>
          <option value="400-500">400-500</option>
          <option value="600-700">600-700</option>
          <option value="700-800">700-800</option>
         </Form.Select>
       </Row>
       <Row className="contact-footer-row">
         <h3>Do You Have Errors On Your Credit Report?</h3>
         <Form.Select  aria-label="Default select example" required as="select" custom name='Errors' onChange={(e) =>setError(e.target.value)}>
         <option value="" disabled selected>Select your Option</option>
         <option value="default" disabled hidden>
           Select Yes or No
         </option>
          <option value="Yes">Yes</option>
          <option value="No">No</option>
       
         </Form.Select>
       </Row>
       <Row className="contact-footer-row">
       <h3>What Credit Repair Service Do You Need?</h3>
         <Form.Select aria-label="Default select example" required as="select" custom name='service' onChange={(e) =>setService(e.target.value)} >
         <option value="" disabled selected>Select your Service</option>
         <option value="default" disabled hidden>
         Choose Your Desired Service
         </option>
         <option value="Home Buying Credit Repair">Home Buying Credit Repair</option>
          <option value="Auto Credit Repair">Auto Credit Repair</option>
          <option value="Error Credit Removal">Error Credit Removal</option>
          <option value="Considering Bankruptcy">Considering Bankruptcy</option>
          <option value="Post Bankruptcy Credit Repair">Post Bankruptcy Credit Repair</option>
          <option value="Identity Theft Credit Repair">Identity Theft Credit Repair</option>
          <option value="Instant A.U. Credit Lines">Instant A.U. Credit Lines</option>
         </Form.Select>
       </Row>
       <Row>
       <div className="navigation-button-contact" onClick={handleSubmitnext}>
        <button >Repair My Credit Now</button>
      </div>
       </Row>
       </Form>
     </Container>
     
       </> : <>
       
       <Container className="contactformnext align-items-center justify-content-center" fluid>
   <Row xs={12} md={12} lg={12}>
       <Col className="firstheadingcontact">
           <h1>Credit Repair Made Easy</h1>
        </Col>
    </Row>

   <Row xs={12} md={12} lg={12} >
        <Col className="firstheadingcontact">
          <h1> You qualify for a free evaluation</h1>
          <p>Complete the form and get your free estimate by a Senior Credit Specialist</p>  
        </Col>
     
   </Row>
  <Form ref={useform} noValidate validated={validated} onSubmit={handleSubmit} className="formcontactnext"> 
    
      <div className="hideform" disabled>
           <input value={creditscore} name="Credit_scores"/>
           <input value={error} name='Errors'/>
           <input value={service} name='service'/>
       </div>
       {/* <Row>
       <div className="navigation-button-contact" onClick={handleSubmitnext}>
        <button >Repair My Credit Now</button>
      </div>
       </Row> */}
       
      <Row className="mb-3">
        <Form.Group as={Col} md="6" controlId="validationCustom01">
          <Form.Label>First name</Form.Label>
          <Form.Control
            required
            type="text"
            placeholder="First name"
            name="first_name"
          />
         <Form.Control.Feedback type="invalid">
            Please provide a First name.
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} md="6" controlId="validationCustom02">
          <Form.Label>Last name</Form.Label>
          <Form.Control
            required
            type="text"
            placeholder="Last name"
            name='last_name'
          />
          <Form.Control.Feedback type="invalid">
            Please provide a last name.
          </Form.Control.Feedback>
        </Form.Group>
      
      </Row>
      <Row className="mb-3">
        <Form.Group as={Col} md="12" controlId="validationCustom03">
          <Form.Label>Email</Form.Label>
          <Form.Control type="email" placeholder="Email" name='email' required/>
          <Form.Control.Feedback type="invalid" >
            Please provide a valid Email.
          </Form.Control.Feedback>
        </Form.Group>
      
      </Row>
      <Row className="mb-3">
        <Form.Group as={Col} md="12" controlId="validationCustom03">
          <Form.Label>Phone number</Form.Label>
          <Form.Control  placeholder="Phone number" required 
        type="tel"
        maxLength="10"
        value={number}
        name="phone"
        onChange={(e) => checkInput(e)} />
          <Form.Control.Feedback type="invalid">
            Please provide a valid Phone number.
          </Form.Control.Feedback>
        </Form.Group>
      
      </Row>
  
      <button type="submit" className="contactformbtn"  value="Send">Get a Free Estimate</button>

      <Row xs={12} md={12} lg={12}>
          <Col>
          <Form.Group className="mb-3" md="12">
             <Form.Text>
   <p>
   * I acknowledge that by clicking "Get Free Estimate" I agree to be contacted by April's
       Credit Repair and its affiliates via prerecorded and/or SMS/MMS text messages via telephone,
        mobile device and/or email. By doing so I waive any registration to any state, federal or corporate
         Do Not Call registry. I understand consent is not required to purchase goods or services. I agree to
          receive approximately 10 messages every month and understand message & data rates may apply.
   </p>
            </Form.Text>
      </Form.Group>
          </Col>
      </Row>
    </Form>
       </Container>
      
       </>
     }

    
    </>
  )
}

export default Contactform