import React from 'react'
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import '../../styles/Resources.css'
import '../../styles/home.css'
import ScrollToTop from '../ScrollToTop';
import resources from '../../assets/images/resources.png'
import Experian from '../../assets/images/experian.png'
import {Helmet} from "react-helmet";

function Resources() {

  return (
   <>
   <ScrollToTop/>
              <Helmet>
                <meta charSet="utf-8" name="description" content="Pull your credit reports and scores through our trusted site https://www.aprilscreditcheck.com. Get relaxed with ongoing credit management with our unlimited access to all 3 of your credit scores."/>
                <title>Get Credit Management Resources | Pull Your Credit Report</title>
                <link rel="canonical" href="http://mysite.com/example" />
              </Helmet>
     <Container className="container-resources" fluid>
   
   
      <div className="dotoneres"></div>
      <div className="dottwores"></div>
      <div className="dotthreeres"></div>
      <div className="dotfourres"></div>
      <div className="dotfiveres"></div>
       <Row>
       <div className="main">
    <div className="headline">
        <h1>Resources</h1>
    </div>
    <div className="werds">
    The first step is pulling your credit reports (soft pulls) and 
    scores through our trusted site below, if you do not already have a monitoring site you use
    </div>

    <div className="credit">
     <a href="https://www.aprilscreditcheck.com/" target="_blank"> <button> 
          Click Here To Pull All Three Credit Bureaus And Scores &nbsp;&nbsp;&nbsp;>
    </button>
    </a>
    </div>

    
    <div className='creditline'>
        <h2>Put your mind at ease</h2>
        <ul>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
  </ul>
    </div>
 
    <div className="next">
    Relax! With aprilscreditcheck.com you can forget about any of the stress and worry of
     endless credit management with our unlimited access to all 3 of your credit scores.
    </div>
 </div>


 
       </Row>
     </Container>
   </>
  )
}

export default Resources